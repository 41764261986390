import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Features`}</h2>
    <ProjectCard title="Banknotes detector" link="https://youtu.be/ngB50JQBsnU" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Recognize and convert banknotes in AR.
    </ProjectCard>
    <ProjectCard title="Prices detector" link="https://youtu.be/19pIXPm7ADg" bg="linear-gradient(to top right, #FFCA2F 0%, #3B6F8D 100%)" mdxType="ProjectCard">
Recognize and convert prices in AR.
    </ProjectCard>
    <h2>{`Apps`}</h2>
    <ProjectCard title="GMoney ios" link="https://itunes.apple.com/us/app/gmoney-ar-currency-converter/id1430788405?ls=1&mt=8" bg="linear-gradient(to bottom left, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
App store
    </ProjectCard>
    <ProjectCard title="GPrice ios" link="https://apps.apple.com/us/app/gprice-ar-currency-converter/id1503245694" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
App store
    </ProjectCard>
    <ProjectCard title="GMoney android" link="https://play.google.com/store/apps/details?id=by.kv.gmoney" bg="linear-gradient(to bottom right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
Google Play
    </ProjectCard>
    <h2>{`Team`}</h2>
    <ProjectCard title="Sasha Khotyashov" link="https://www.linkedin.com/in/sashakhotiashov" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  CEO
    </ProjectCard>
    <ProjectCard title="Dima Komissarov" link="https://www.linkedin.com/in/komissaro/ " bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  CMO
    </ProjectCard>
    <ProjectCard title="Kirill Vashilo" link="https://www.linkedin.com/in/kirill-vashilo-26693175" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  CTO
    </ProjectCard>
    <ProjectCard title="Ivan Vedenin" link="https://www.linkedin.com/in/ivan-vedenin" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Sales
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      