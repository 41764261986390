import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, We are Beaverhood LLC!`}</h1>
    <p>{`GPrice - technology for banknotes and prices recognition. `}<br />{`
`}<a parentName="p" {...{
        "href": "https://docs.google.com/presentation/d/1SHUumPfkqeQdnX1soZxqUpKmMHlA9KukKjMfWvAb6Zs"
      }}>{`Pitch-deck`}</a>{` `}<br /></p>
    <meta name="yandex-verification" content="b99f258a8b4427b1" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      