import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Contacts`}</h2>
    <p><a parentName="p" {...{
        "href": "darkwinddev@gmail.com"
      }}>{`Email us`}</a>{` or find on other platforms: `}<a parentName="p" {...{
        "href": "https://www.facebook.com/gmoneyapp/"
      }}>{`Facebook`}</a>{` & `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/company/gmoney"
      }}>{`Linkedin`}</a></p>
    <p>{`Phone: `}<a parentName="p" {...{
        "href": "tel:+12163954902"
      }}>{`+12163954902`}</a></p>
    <p>{`Company address:`}</p>
    <p>
30 N Gould St, STE 4000 <br />
Sheridan, WY 82801 <br />
USA </p>
    <p>{`Beaverhood LLC`}</p>
    <script src="//code-ya.jivosite.com/widget/JPf4ILfWPy" async></script>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      