import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Progress`}</h1>
    <p>{`Technology - ready `}<br />{`
2 prototype apps in Stores `}<br />{`
7000 prototype downloads in App Store `}<br />{`
18000 recognized banknotes `}<br />{`
2 pre-sale B2B cases `}<br />{`
`}<a parentName="p" {...{
        "href": "https://www.startupschool.org/companies/W1Cyy8hA0bgkEg"
      }}>{`Finish YCombinator startup school`}</a>{` `}<br />{`
`}<a parentName="p" {...{
        "href": "https://www.gistnetwork.org/meetentrepreneurs"
      }}>{`Finish GIST incubator in Cleveland and Washington DC`}</a>{` `}<br /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      